import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
// or @mui/lab/Adapter{Dayjs,Luxon,Moment} or any valid date-io adapter
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";

import {
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "src/ui";
import { useGetScheduleQuery } from "./UnauthScheduleService";
import { ParseableDate } from "@mui/lab/internal/pickers/constants/prop-types";

const UnauthSchedulePage = () => {
  const startDate = useRef(dayjs().startOf("day"));
  const { scheduleId } = useParams();
  const [value, setValue] = useState<ParseableDate<Dayjs>>(startDate.current);
  const { data, isError } = useGetScheduleQuery(
    {
      id: scheduleId as string,
      startDate: startDate.current.toISOString(),
    },
    { skip: !scheduleId }
  );

  if (isError) {
    return (
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2">Error</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
  if (!data) {
    return (
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2">Loading</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  const { name, free } = data;

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2">Appointment with {name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      minDate={startDate.current}
                      maxDate={dayjs().add(1, "year")}
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      shouldDisableDate={(date) => {
                        return !free[date.toISOString()];
                      }}
                      // @ts-ignore
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {!!value && (
                    <List>
                      {free[(value as Dayjs).toISOString()].map(
                        ({ start, end }) => (
                          <ListItem key={start}>
                            <ListItemText primary={`${start} - ${end}`} />
                          </ListItem>
                        )
                      )}
                    </List>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UnauthSchedulePage;
