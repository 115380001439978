import { useParams } from "react-router-dom";

import EditScheduleForm from "./EditScheduleForm";
import { Grid, Typography } from "src/ui";
import { AddTitleAndNavigation } from "src/Layout";
import { AuthenticatedPage } from "src/Authentication";

const EditSchedulePage = () => {
  const { scheduleId } = useParams();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">Edit</Typography>
      </Grid>
      <Grid item xs={12}>
        {scheduleId && <EditScheduleForm id={scheduleId} />}
      </Grid>
    </Grid>
  );
};

export default AuthenticatedPage(AddTitleAndNavigation(EditSchedulePage));
