import { ReactNode } from "react";
import BaseTitle from "./BaseTitle";

interface TitleProps {
  children: ReactNode;
}

const Title = ({ children }: TitleProps) => {
  return <BaseTitle title="Schedule">{children}</BaseTitle>;
};

export default Title;
