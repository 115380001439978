import { Box, Card, CardContent, CardHeader, Container, Link } from "src/ui";

import { AddTitle } from "src/Layout";

const SignInPage = () => {
  return (
    <Container maxWidth="xs">
      <Card elevation={0}>
        <CardHeader title="Sign in" />
        <CardContent sx={{ textAlign: "center" }}>
          <Link href={`${process.env.REACT_APP_SERVER_DOMAIN}/google/auth`}>
            <Box
              component="img"
              sx={{ maxWidth: "75%" }}
              src="/sign-in-with-google.png"
              alt="Sign in with Google"
            />
          </Link>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddTitle(SignInPage);
