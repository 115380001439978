import { useState } from "react";
import { Link } from "react-router-dom";

import {
  AddIcon,
  EditIcon,
  Fab,
  Grid,
  Typography,
  DeleteIcon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "src/ui/";
import DeleteDialog from "./DeleteDialog";
import {
  useDeleteScheduleMutation,
  useGetSchedulesQuery,
} from "./ScheduleService";
import { AddTitleAndNavigation } from "src/Layout";
import { AuthenticatedPage } from "src/Authentication";

const SchedulesPage = () => {
  const [deleteSchedule] = useDeleteScheduleMutation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState<string>();
  const { data: schedules = [] } = useGetSchedulesQuery();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2">Schedules</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {schedules.map(({ id, name, url }) => (
                  <TableRow key={id}>
                    <TableCell component="th" scope="row">
                      {name}
                    </TableCell>
                    <TableCell sx={{ wordBreak: "break-all" }}>{url}</TableCell>
                    <TableCell>
                      <IconButton component={Link} to={`/schedules/${id}/edit`}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setIsDialogOpen((isNowOpen) => !isNowOpen);
                          setToDelete(id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <DeleteDialog
        open={isDialogOpen}
        handleClose={(shouldDelete) => {
          if (shouldDelete && toDelete) {
            deleteSchedule(toDelete);
          }
          setToDelete(undefined);
          setIsDialogOpen(false);
        }}
      />
      <Fab
        color="primary"
        sx={(theme) => ({
          position: "absolute",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          [theme.breakpoints.down("sm")]: {
            bottom: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(
              1
            )})`,
          },
        })}
        component={Link}
        to="/schedules/add"
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default AuthenticatedPage(AddTitleAndNavigation(SchedulesPage));
