import { ReactNode } from "react";

import { AppBar, Grid, Toolbar, Typography } from "src/ui";

interface BaseTitleProps {
  title: string;
  children: ReactNode;
}

const BaseTitle = ({ title, children }: BaseTitleProps) => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography component="h1" variant="h6" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default BaseTitle;
