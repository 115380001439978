import { FORM_ERROR } from "final-form";
import { useNavigate } from "react-router-dom";

import {
  useGetScheduleQuery,
  useUpdateScheduleMutation,
} from "./ScheduleService";
import ScheduleForm from "./ScheduleForm";
import { hasErrorProperty, isFetchBaseQueryError } from "src/utils";

interface EditScheduleFormProps {
  id: string;
}

const EditScheduleForm = ({ id }: EditScheduleFormProps) => {
  const navigate = useNavigate();
  const { data: schedule } = useGetScheduleQuery(id, { skip: !id });

  const [updateSchedule] = useUpdateScheduleMutation();

  return schedule ? (
    <ScheduleForm
      buttonText="Edit Schedule"
      initialValues={{
        name: schedule.name,
        description: schedule.description,
        calendarId: schedule.calendarId,
      }}
      onSubmit={async (schedule) => {
        try {
          await await updateSchedule({ ...schedule, id }).unwrap();
        } catch (e) {
          if (isFetchBaseQueryError(e) && hasErrorProperty(e.data)) {
            return { [FORM_ERROR]: e.data.error };
          }
        }

        // TODO: Maybe snackbar to notify that it is created
        navigate("/schedules");
      }}
    />
  ) : (
    <div />
  );
};

export default EditScheduleForm;
