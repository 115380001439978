import { FORM_ERROR } from "final-form";
import { useNavigate } from "react-router-dom";

import ScheduleForm from "./ScheduleForm";
import { useCreateScheduleMutation } from "./ScheduleService";
import { hasErrorProperty, isFetchBaseQueryError } from "src/utils";

const NewScheduleForm = () => {
  const navigate = useNavigate();
  const [createSchedule] = useCreateScheduleMutation();

  return (
    <ScheduleForm
      buttonText="Create Schedule"
      onSubmit={async (schedule) => {
        try {
          await createSchedule(schedule).unwrap();
        } catch (e) {
          if (isFetchBaseQueryError(e) && hasErrorProperty(e.data)) {
            return { [FORM_ERROR]: e.data.error };
          }
        }

        // TODO: Maybe snackbar to notify that it is created
        navigate("/schedules");
      }}
    />
  );
};

export default NewScheduleForm;
