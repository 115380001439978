import { configureStore } from "@reduxjs/toolkit";

import { userApi } from "src/User";
import { calendarApi } from "src/Calendar";
import { scheduleApi } from "src/Schedule";
import { unauthScheduleApi } from "src/UnauthSchedule";

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [unauthScheduleApi.reducerPath]: unauthScheduleApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      calendarApi.middleware,
      scheduleApi.middleware,
      unauthScheduleApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
