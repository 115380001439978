import { ReactNode } from "react";
import BaseTitleAndNavigation from "./BaseTitleAndNavigation";

const routes = [
  {
    name: "Get started",
    route: "/",
  },
  {
    name: "Schedules",
    route: "/schedules",
  },
  {
    name: "Settings",
    route: "/settings",
  },
];

interface TitleAndNavigationProps {
  children: ReactNode;
}

const TitleAndNavigation = ({ children }: TitleAndNavigationProps) => {
  return (
    <BaseTitleAndNavigation title="Schedule" routes={routes}>
      {children}
    </BaseTitleAndNavigation>
  );
};

export default TitleAndNavigation;
