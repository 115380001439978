import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "src/ui/";

interface DeleteDialogProps {
  open: boolean;
  handleClose: (shouldDelete: boolean) => void;
}

const DeleteDialog = ({ open, handleClose }: DeleteDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">Delete schedule</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          When this schedule is deleted, others will not be able to book you
          through this schedule. Do you wish to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={() => handleClose(true)} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
