import { ComponentType } from "react";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
} from "src/ui";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const BaseTitleAndNavigation: ComponentType<{
  title: string;
  routes: { name: string; route: string }[];
}> = ({ children, title, routes }) => {
  return (
    <Box component="div" sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography component="h1" variant="h6" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: "none", sm: "block" },
        }}
        variant="permanent"
        PaperProps={{ sx: { width: drawerWidth } }}
      >
        <Toolbar />
        <Box component="div" sx={{ overflow: "auto" }}>
          <nav>
            <List>
              {routes.map(({ name, route }) => (
                <ListItem key={name}>
                  <ListItemButton component={Link} to={route}>
                    {name}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          padding: theme.spacing(1),
          [theme.breakpoints.down("sm")]: {
            marginBottom: theme.mixins.toolbar.minHeight,
          },
        })}
      >
        <Toolbar />
        {children}
      </Box>
      <BottomNavigation
        value={undefined}
        showLabels
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100vw",
          display: { xs: "flex", sm: "none" },
        }}
      >
        {routes.map(({ name, route }) => (
          <BottomNavigationAction
            component={Link}
            to={route}
            key={name}
            label={name}
            value={route}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
};

export default BaseTitleAndNavigation;
