import { ComponentType } from "react";

import { useGetUserQuery } from "src/User";
import SignInPage from "./SignInPage";

const AuthenticatedPage = <Props,>(WrappedComponent: ComponentType<Props>) => {
  const Wrapped = (props: Props) => {
    const { isError, isFetching } = useGetUserQuery();

    if (isFetching) {
      return <div>Loading...</div>;
    } else if (!isError) {
      return <WrappedComponent {...props} />;
    } else {
      // TODO: Examine error and display unauth or server error
      return <SignInPage />;
    }
  };

  return Wrapped;
};

export default AuthenticatedPage;
