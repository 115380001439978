import { Grid, Link, Skeleton, Typography } from "src/ui";
import { useListCalendarsQuery } from "src/Calendar";
import { AddTitleAndNavigation } from "src/Layout";
import { AuthenticatedPage } from "src/Authentication";

const SettingsPage = () => {
  const { data, isFetching } = useListCalendarsQuery();

  const hasData = data?.length;

  // TODO: Allow functionality to reconnect google account
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">Settings</Typography>
        <Typography variant="h3">Google</Typography>
      </Grid>
      {!hasData ? (
        <Grid item xs={12}>
          {isFetching ? (
            <Skeleton variant="rectangular" />
          ) : (
            <Link
              href={`${process.env.REACT_APP_SERVER_DOMAIN}/google/auth?scope=https://www.googleapis.com/auth/calendar.readonly`}
            >
              <img src="/sign-in-with-google.png" alt="Sign in with Google" />
            </Link>
          )}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography>Connected to Google account</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AuthenticatedPage(AddTitleAndNavigation(SettingsPage));
