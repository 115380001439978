import { Grid, Typography } from "src/ui";
import { AddTitleAndNavigation } from "src/Layout";
import { AuthenticatedPage } from "src/Authentication";

const GetStartedPage = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">Welcome</Typography>
        <Typography paragraph>
          Go to Settings to add your Google account calendars.
        </Typography>
        <Typography paragraph>
          Create a schedule type by clicking the &ldquo;Schedules&ldquo; link
          and then pressing the &ldquo;+&ldquo; button. Fill out the rules of
          the schedule type to be booked appropriately
        </Typography>
        <Typography paragraph>
          Share the link created by the schedule type with those who would like
          to book a meeting with you.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuthenticatedPage(AddTitleAndNavigation(GetStartedPage));
