import { ComponentType, ReactNode } from "react";

const AddLayoutToComponent = <Props,>(
  WrappedComponent: ComponentType<Props>,
  Layout: ComponentType<{ children: ReactNode }>
) => {
  return (props: Props) => (
    <Layout>
      <WrappedComponent {...props} />
    </Layout>
  );
};

export default AddLayoutToComponent;
