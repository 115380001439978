import { Grid, Typography } from "src/ui";
import { AddTitleAndNavigation } from "src/Layout";
import NewScheduleForm from "./NewScheduleForm";
import { AuthenticatedPage } from "src/Authentication";

const AddSchedulePage = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2">Add</Typography>
      </Grid>
      <Grid item xs={12}>
        <NewScheduleForm />
      </Grid>
    </Grid>
  );
};

export default AuthenticatedPage(AddTitleAndNavigation(AddSchedulePage));
