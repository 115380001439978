import { ReactElement } from "react";
import { Form } from "react-final-form";
import { setIn } from "final-form";
import { TextField } from "mui-rff";
import { InferType, object, string, ValidationError } from "yup";

import { Button, Grid, MenuItem, Typography } from "src/ui";
import { useListCalendarsQuery } from "src/Calendar";

const SHORT_STRING_LENGTH = 125;
const LONG_STRING_LENGTH = 255;

const newScheduleSchema = object({
  name: string()
    .trim()
    .max(SHORT_STRING_LENGTH)
    .required()
    .label("Schedule name"),
  description: string().trim().max(LONG_STRING_LENGTH),
  calendarId: string().trim().required().label("Calendar to use"),
});

type NewSchedule = InferType<typeof newScheduleSchema>;

interface ScheduleFormProps {
  onSubmit: (schedule: NewSchedule) => void;
  buttonText: string;
  initialValues?: NewSchedule;
}

const ScheduleForm = ({
  buttonText,
  initialValues,
  onSubmit,
}: ScheduleFormProps): ReactElement => {
  const { data: calendars, isFetching } = useListCalendarsQuery();

  const hasData = calendars?.length;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={async (values) => {
        // TODO: If I want to every generalize this, I can add the following code with
        // some other code but just know I need more work for this to work
        // if (typeof schema === 'function') {
        //   schema = schema();
        // }
        try {
          await newScheduleSchema.validate(values, { abortEarly: false });
        } catch (err) {
          let errors = {};

          if (err instanceof ValidationError) {
            errors = err.inner.reduce((formError, innerError) => {
              return setIn(formError, innerError.path!, innerError.message);
            }, {});
          }

          return errors;
        }
      }}
      render={({ handleSubmit, hasSubmitErrors, submitError, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Schedule name"
                name="name"
                required
                disabled={submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Schedule description"
                name="description"
                disabled={submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="calendarId"
                label="Calendar to use"
                select
                required
                disabled={isFetching || !hasData || submitting}
              >
                <MenuItem>
                  <em>Choose a calendar</em>
                </MenuItem>
                {!!calendars &&
                  calendars.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={submitting}
              >
                {buttonText}
              </Button>
              {hasSubmitErrors && (
                <Typography color="error">{submitError}</Typography>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ScheduleForm;
