import { Routes, Route } from "react-router-dom";

import { GetStartedPage } from "src/GetStarted";
import { SignInPage } from "src/Authentication";
import { SettingsPage } from "src/Settings";
import { AddSchedulePage, EditSchedulePage, SchedulesPage } from "src/Schedule";
import { UnauthSchedulePage } from "src/UnauthSchedule";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<GetStartedPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/schedules" element={<SchedulesPage />} />
      <Route path="/schedules/add" element={<AddSchedulePage />} />
      <Route
        path="/schedules/:scheduleId/edit"
        element={<EditSchedulePage />}
      />
      <Route path="/s/:scheduleId" element={<UnauthSchedulePage />} />
    </Routes>
  );
};

export default AppRoutes;
