import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { CssBaseline, theme, ThemeProvider } from "src/ui";
import { store } from "src/store";
import AppRoutes from "./AppRoutes";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
